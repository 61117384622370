<template>
  <div class="dashboard">Seja bem vindo {{ user }}</div>
</template>

<script>
export default {
  name: "dashboard",
  data() {
    return {
      user: "",
    };
  },
  components: {},
  methods: {
    addAddressToMap({ city, country }) {
      this.$refs.dashboardMap.addAddress({ city: city.text, country });
    },
  },
  mounted() {
    let regUser = JSON.parse(this.$store.state.userDados);
    if (regUser) this.user = regUser.nome;
  },
};
</script>

<style lang="scss">
.row-equal .flex {
  .va-card {
    height: 100%;
  }
}

.dashboard {
  .va-card {
    margin-bottom: 0 !important;
    &__title {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
